import React, { useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { useFormspark } from "@formspark/use-formspark";

export default function Quiz() {
  const [status, setStatus] = useState("quiz");
  const [name, setName] = useState("");
  const [answer, setAnswer] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(true);

  return (
    <>
      <div className="header spiel" style={{ zIndex: 1 }}></div>
      <div
        className="spiel-content"
        style={{ zIndex: 999999, position: "relative" }}
      >
        <form className="layout_form formular-box cr_form cr_font spiel-formular">
          <div className="cr_body cr_page cr_font formbox">
            <div style={{ fontSize: 20 }}>
              Das Gewinnspiel ist beendet. Eine Teilnahme ist nicht mehr
              möglich.
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
