import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";

export default function Datenschutz() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="header small">
        <h2 className="header-title-single">Datenschutz</h2>
      </div>
      <div className="content">
        <div className="inner">
          <h3>
            1. Datenschutzerklärung der Stadtwerke Solingen GmbH –
            Verkehrsbetrieb{" "}
          </h3>
          <p>1.1 Einleitung </p>
          <p>
            Wir legen Wert auf den Schutz Ihrer persönlichen Daten. Wir
            behandeln Ihre personenbezogenen Daten vertraulich und entsprechend
            der gesetzlichen Datenschutzvorschriften.
          </p>
          <p>
            Mit dieser Erklärung und den weiter unten aufgeführten ergänzenden
            Informationen zur Nutzung unserer Website und den hier angebotenen
            Online-Diensten möchten wir unseren Informations- und
            Transparenzpflichten hinsichtlich der Erhebung und Verarbeitung
            Ihrer personenbezogenen Daten im Zusammenhang mit der Dienstleistung
            des öffentlichen Personennahverkehr nachkommen.
          </p>
          <p>
            Personenbezogene Daten sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person beziehen. Als
            identifizierbar wird eine natürliche Person angesehen, die direkt
            oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie
            einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
            Online-Kennung oder zu einem oder mehreren besonderen Merkmalen
            identifiziert werden kann, die Ausdruck der Identität dieser
            natürlichen Person sind.{" "}
          </p>
          <p>1.2 Verantwortliche Stelle </p>
          <p>
            Verantwortlicher im Sinne der gesetzlichen Bestimmungen zum
            Datenschutz (z. B. DS-GVO) für die Verarbeitung Ihrer
            personenbezogenen Daten ist:{" "}
          </p>
          <ul>
            <li>
              Stadtwerke Solingen GmbH, Beethovenstraße 210, 42655 Solingen{" "}
            </li>
            <li>www.stadtwerke-solingen.de </li>
            <li>Telefon 0212 295-0 </li>
          </ul>
          <p>
            Unser Datenschutzbeauftragter steht Ihnen für Fragen zur
            Verarbeitung Ihrer personenbezogenen Daten gerne unter folgenden
            Kontaktdaten zur Verfügung:{" "}
          </p>
          <ul>
            <li>Datenschutz Stadtwerke Solingen </li>
            <li>
              Stadtwerke Solingen GmbH, Beethovenstraße 210, 42655 Solingen{" "}
            </li>
            <li>Telefon 0212 295-1030 </li>
            <li>swsg.datenschutz@stadtwerke-solingen.de </li>
          </ul>
          <p>1.3 Art, Zweck und Rechtsgrundlage erhobener Daten </p>
          <p>Wir verarbeiten folgende Kategorien personenbezogener Daten: </p>
          <ul>
            <li>
              Persönliche Angaben (z. B. Name, Anschrift, E-Mail-Adresse,
              Geburtsdatum, Telefonnummer){" "}
            </li>
            <li>
              Daten zum Fahrausweis (z. B. Kundennummer, Chipkartennummer,
              Ticketart)
            </li>
            <li>
              Abrechnungsdaten (z. B. Bankverbindungsdaten und
              Zahlungsinformationen){" "}
            </li>
            <li>Daten zum Zahlungsverhalten </li>
          </ul>
          <p>
            Die Sie betreffenden personenbezogenen Daten werden zu den folgenden
            Zwecken auf folgender Rechtsgrundlage verarbeitet:{" "}
          </p>
          <ul>
            <li>
              Im Fall von Zahlungsrückständen kann eine Bewertung Ihrer
              Kreditwürdigkeit sowie Mitteilung von Anhaltspunkten zur
              Ermittlung Ihrer Kreditwürdigkeit durch die Auskunftei
              Creditreform Solingen Kirschner GmbH & Co. KG, Kuller Str. 58,
              42651 Solingen / Tel: 0212 2503-0 / Fax: 0212 2503-230
              / info@solingen.creditreform.de/ http://www.creditreform-solingen.deauf
              Grundlage von Art. 6 Abs. 1 lit. b) und f) DS-GVO erfolgen.
              Verarbeitungen auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO
              dürfen nur erfolgen, soweit dies zur Wahrung berechtigter
              Interessen des Lieferanten oder Dritter erforderlich ist und nicht
              die Interessen oder Grundrechte und Grundfreiheiten der
              betroffenen Person, die den Schutz personenbezogener Daten
              erfordern, überwiegen. Wir übermitteln hierzu personenbezogene
              Daten über die Beantragung und gegebenenfalls Beendigung des
              Abonnements sowie Daten über nicht vertragsgemäßes Verhalten an
              die genannte Auskunftei. Der Datenaustausch mit der Auskunftei
              dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung
              von Kreditwürdigkeitsprüfungen von Kunden (§§ 505a und 506 des
              Bürgerlichen Gesetzbuches). Die Auskunftei verarbeitet die
              erhaltenen Daten und verwendet sie zudem zum Zwecke der
              Profilbildung (Scoring), um Dritten Informationen zur Beurteilung
              der Kreditwürdigkeit des Kunden zu geben. In die Berechnung der
              Kreditwürdigkeit fließen unter anderem die Anschriftendaten des
              Kunden ein. Nähere Informationen zur Tätigkeit der Auskunftei
              können online
              unter https://www.creditreform-solingen.de/eu-dsgvo.htmleingesehen
              werden.
            </li>
          </ul>
          <p>1.4 Empfänger der Daten </p>
          <p>
            Eine Offenlegung bzw. Übermittlung Ihrer personenbezogenen Daten
            erfolgt ausschließlich im Rahmen der unter 1.3. genannten Zwecke
            gegenüber folgenden Empfängern bzw. Kategorien von Empfängern:
            IT-Dienstleister, Abrechnungsdienstleister,
            Druckdienstleister,  Dienstleister für Sperrungen und
            Wiederinbetriebnahme, Geldinstitute, Inkasso-Dienstleister und
            Rechtsanwälte und öffentliche Stellen und Institutionen (z. B.
            Finanzbehörden) bei Vorliegen einer entsprechenden
            Verpflichtung/Berechtigung.{" "}
          </p>
          <p>
            Eine Übermittlung Ihrer personenbezogenen Daten an oder in
            Drittländer oder an internationale Organisationen erfolgt nicht.{" "}
          </p>
          <p>1.5 Dauer der Speicherung und Löschung der Daten </p>
          <p>
            Personenbezogene Daten werden zu den unter 2. genannten Zwecken
            solange gespeichert, wie dies für die Erfüllung dieser Zwecke
            erforderlich ist.{" "}
          </p>
          <p>1.6 Betroffenenrechte </p>
          <p>
            Sie haben uns gegenüber, insbesondere folgende Rechte hinsichtlich
            der Sie betreffenden personenbezogenen Daten:{" "}
          </p>
          <ul>
            <li>
              Recht auf Auskunft über Ihre gespeicherten personenbezogenen Daten
              (Art. 15 DS-GVO),{" "}
            </li>
            <li>
              Recht auf Berichtigung, wenn die Sie betreffenden gespeicherten
              Daten fehlerhaft, veraltet oder sonst wie unrichtig sind (Art. 16
              DS-GVO),{" "}
            </li>
            <li>
              Recht auf Löschung, wenn die Speicherung unzulässig ist, der Zweck
              der Verarbeitung erfüllt und die Speicherung daher nicht mehr
              erforderlich ist oder Sie eine erteilte Einwilligung zur
              Verarbeitung bestimmter personenbezogener Daten widerrufen haben
              (Art. 17 DS-GVO),{" "}
            </li>
            <li>
              Recht auf Einschränkung der Verarbeitung, wenn eine der in Art. 18
              Abs. 1 lit. a) bis d) DS-GVO genannten Voraussetzungen gegeben ist
              (Art. 18 DS-GVO),{" "}
            </li>
            <li>
              Recht auf Übertragung der von Ihnen bereitgestellten, Sie
              betreffenden personenbezogenen Daten (Art. 20 DS-GVO),{" "}
            </li>
            <li>
              Recht auf Widerruf einer erteilten Einwilligung, wobei der
              Widerruf die Rechtmäßigkeit der bis dahin aufgrund der
              Einwilligung erfolgten Verarbeitung nicht berührt (Art. 7 Abs. 3
              DS-GVO) und{" "}
            </li>
            <li>
              Recht auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DS-GVO).
              Die für uns zuständige Aufsichtsbehörde ist die Landesbeauftragte
              für Datenschutz und Informationsfreiheit Nordrhein-Westfalen,
              Kavalleriestraße 2-4, 40213 Düsseldorf.{" "}
            </li>
          </ul>
          <p>1.7 Widerspruchsrecht </p>
          <p>
            Sie können uns gegenüber jederzeit der Verarbeitung Ihrer
            personenbezogenen Daten ohne Angabe von Gründen widersprechen. Wir
            werden die personenbezogenen Daten im Falle eines begründeten
            Widerspruchs grundsätzlich nicht mehr für die betreffenden Zwecke
            verarbeiten und die Daten löschen, es sei denn, wir können zwingende
            Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
            und Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.{" "}
          </p>
          <p>Der Widerspruch ist zu richten an: </p>
          <ul>
            <li>Stadtwerke Solingen GmbH </li>{" "}
            <li>Beethovenstr. 210, 42655 Solingen </li>{" "}
            <li>E-Mail: info@stadtwerke-solingen.de </li>
          </ul>
          <p>1.8 Bereitstellung personenbezogener Daten </p>
          <p>
            Im Rahmen unserer Geschäftsbeziehung müssen Sie diejenigen
            personenbezogenen Daten bereitstellen, die für die Aufnahme und
            Durchführung der Geschäftsbeziehung und der Erfüllung der damit
            verbundenen vertraglichen Pflichten erforderlich sind oder zu deren
            Erhebung wir gesetzlich verpflichtet sind. Ohne diese Daten können
            wir den Vertrag nicht abschließen.{" "}
          </p>
          <p>1.9 Datenquellen </p>
          <p>
            Wir verarbeiten personenbezogene Daten, die wir im Rahmen unserer
            Geschäftsbeziehung von Ihnen erhalten. Wir verarbeiten auch
            personenbezogene Daten, die wir zulässigerweise von Unternehmen
            unseres Konzerns oder von Dritten, z.B. Verkehrsverbund Rhein-Ruhr
            (VRR) oder Auskunfteien, erhalten.
          </p>
          <h3>
            2. Ergänzende Informationen zum Datenschutz bei der Nutzung dieser
            Website sowie der hier angebotenen Online-Dienste{" "}
          </h3>
          <p>2.1 Art, Zweck und Rechtsgrundlage erhobener Daten </p>
          <p>
            Die Nutzung unserer Website ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Die Angabe von personenbezogenen
            Daten ist freiwillig.{" "}
          </p>
          <p>
            Der Zweck der Verarbeitung von Daten ist das Betreiben einer Website
            mit Informationen zu unserem Dienstleistungsangebot nebst
            Kontaktmöglichkeiten und Vertragsabschlüssen.{" "}
          </p>
          <p>
            Personenbezogene Daten werden auf unserer Website dann erhoben, wenn
            dies{" "}
          </p>
          <ul>
            <li>
              für die Nutzung der Website (Rechtsgrundlage: Artikel 6 Absatz 1
              a) und/oder Artikel 6 Absatz 1 b) Datenschutzgrundverordnung),{" "}
            </li>
            <li>
              zur Wahrung unseres Interesses zur Verbesserung des
              Nutzererfahrung und zur Aufrechterhaltung der Sicherheit der
              Nutzung (Rechtsgrundlage: Artikel 6 Absatz 1 f)
              Datenschutzgrundverordnung),{" "}
            </li>
            <li>
              für die Nutzung der auf der Website angebotenen Leistungen sowie
              vorvertraglicher Maßnahmen, insbesondere etwa für Formulareingaben
              (Rechtsgrundlage: Artikel 6 Absatz 1 a) und/ oder Artikel 6 Absatz
              1 b) Datenschutzgrundverordnung) oder{" "}
            </li>
            <li>
              für einen Vertragsabschluss und für die Vertragsdurchführung
              (Rechtsgrundlage: Artikel 6 Absatz 1 a) und b)
              Datenschutzgrundverordnung){" "}
            </li>
          </ul>
          <p>erforderlich ist. </p>
          <p>
            Weitere Details zur Verarbeitung von Daten finden Sie nachfolgend
            unter entsprechenden Überschriften.{" "}
          </p>
          <p>2.2 Zugriffsdaten/Server-Logfiles </p>
          <p>
            Bei dem Besuch unserer Website speichern die Server automatisch die
            Informationen, die Ihr Browser sendet, sog. Server-Logfiles. Die
            Informationen umfassen{" "}
          </p>
          <ul>
            <li>Name der abgerufenen Webseite, </li>
            <li>Datei, </li>
            <li>Datum und Uhrzeit des Abrufs, </li>
            <li>Meldung über erfolgreichen Abruf, </li>
            <li>Browsertyp nebst Version, </li>
            <li>Betriebssystem des Nutzers, </li>
            <li>Betriebssystem des Nutzers, </li>
            <li>IP-Adresse und </li>
            <li>Provider. </li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen erfolgt
            nicht. Die Informationen werden ausschließlich zur Analyse und
            Aufrechterhaltung des technischen Betriebs der Server und des
            Netzwerkes benutzt.{" "}
          </p>
          <p>2.3 Kontaktformular </p>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.{" "}
          </p>
          <p>2.4 Shariff-Methode zum Teilen von Blog-Beiträgen </p>{" "}
          <p>
            Die Betreiberin der Webseite ermöglicht das Teilen von Beiträgen auf
            Facebook über Share-Buttons unter Verwendung der sog.
            “Shariff”-Methode. Mit dieser Lösung wird ein unmittelbarer Kontakt
            zwischen dem Nutzer und dem Social-Media-Portal erst dann
            hergestellt, wenn der Nutzer aktiv auf den Share-Button klickt. Der
            Klick auf den Share-Button stellt damit eine Einwilligungserklärung
            des Nutzers in die Datenübermittlung an das jeweilige
            Social-Media-Portal dar.{" "}
          </p>{" "}
          <p>
            Weitere Informationen zu “Shariff: Social-Media-Buttons mit
            Datenschutz” finden Sie
            unter https://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html.{" "}
          </p>{" "}
          <p>2.4.1 Facebook </p>{" "}
          <p>
            Die Betreiberin weist den Nutzer darauf hin, dass nach einem Klick
            auf den Facebook-Button Daten über den Nutzer und seinen
            Webseitenbesuch mit dem Facebook-Server ausgetauscht werden.
            Facebook ist ein Social-Media-Portal der Firma Facebook Inc., 1601
            Willow Road, Menlo Park, CA 94025 (USA). Ist der Nutzer Mitglied bei
            Facebook und bei Facebook eingeloggt, werden zudem Informationen
            über seinen Webseitenbesuch seinem Nutzerprofil zugeordnet. Die
            Betreiberin ist nicht über den Inhalt der ausgetauschten Daten
            informiert. Unter www.facebook.com/policy.phperhält der Nutzer
            weitere Datenschutzhinweise mit detaillierten Informationen über die
            Erhebung und Nutzung von Daten durch Facebook.{" "}
          </p>{" "}
          <p>2.5 Kontakt via E-Mail </p>{" "}
          <p>
            Wenn Sie uns per E-Mail Anfragen zukommen lassen, werden Ihre
            dortigen Angaben aus der E-Mail inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. Wir werden die von Ihnen
            freiwillig zur Verfügung gestellten Daten streng vertraulich
            behandeln. Freiwillig von Ihnen mitgeteilten personenbezogenen Daten
            speichern und nutzen wir, soweit es also für die weitere
            Korrespondenz mit Ihnen erforderlich ist.{" "}
          </p>{" "}
          <p>2.6 Cookies </p>
          <p>
            Die Internetseiten verwenden teilweise sogenannte “Cookies”. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.{" "}
          </p>{" "}
          <p>
            Die meisten der von uns verwendeten Cookies sind sogenannte
            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.{" "}
          </p>{" "}
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.{" "}
          </p>{" "}
          <p>2.7 1&1-WebAnalytics </p>{" "}
          <p>
            Auf unseren Internetseiten benutzen wir 1&1-WebAnalytics, einen
            Webanalysedienst der 1und1 Internet SE, Elgendorfer Straße 57, 56410
            Montabaur. 1und1 wertet über seinen Dienst 1&1-WebAnalytics das
            Nutzerverhalten auf unserer Website aus und protokolliert die
            Nutzungsdaten. Cookies kommen dabei laut 1&1 nicht zum Einsatz. Die
            Informationen über die Benutzung unserer Webseiten werden an einen
            Server von 1und1 übertragen und dort anonymisiert gespeichert. 1und1
            stellt unter Verwendung der ermittelten Nutzungsdaten anonyme
            Reports über die Webseitenaktivitäten zusammen. Ihre IP-Adresse wird
            laut 1und1 dafür anonymisiert und nur gekürzt weiterverarbeitet um
            einen Bezug zu Ihrer Person auszuschließen.{" "}
          </p>{" "}
          <p>2.11 Google Maps </p>{" "}
          <p>
            Diese Website verwendet außerdem die Google Maps der Google LLC., um
            geographische Informationen und Anfahrtsbeschreibungen darzustellen
            und zu berechnen. Bei der Nutzung von Google Maps werden
            Informationen über die Nutzung unserer Internetseite einschließlich
            Ihrer IP-Adresse und die von Ihnen bei Google Maps eingegeben Daten
            an Google übertragen und dort gespeichert. Sie können eine solche
            Datenübertragung jedoch verhindern, wenn Sie in Ihrem Browser
            “Javascript” deaktivieren. In dem Falle können jedoch keine Karten
            angezeigt werden. Weitere Informationen darüber wie Google Maps Ihre
            Daten verwendet finden Sie
            unter: https://www.google.com/intl/de_de/help/terms_maps.html{" "}
          </p>
          <p>
            Ausführliche Details zu Wahlmöglichkeiten und Datenschutz finden Sie
            im Datenschutz-Center von google.de
            unter https://www.google.de/intl/de/policies/privacy/.
          </p>
          <p>2.12 Empfänger von personenbezogenen Daten </p>
          <ul>
            <li>
              Sofern Sie Bestellungen für eine Ticketart bei uns beauftragen,
              werden Ihre personenbezogenen Daten zum Zwecke der Überprüfung
              Ihrer Bonität ebenfalls durch die Auskunftei Creditreform Solingen
              Kirschner GmbH & Co. KG, Kuller Str. 58, 42651 Solingen / Tel:
              0212 2503-0 / Fax: 0212 2503-230
              / info@solingen.creditreform.de/ http://www.creditreform-solingen.deauf
              Grundlage von Art. 6 Abs. 1 lit. b) und f) DS-GVO erfolgen. Die
              Überprüfung Ihrer Bonität ist deshalb erforderlich, weil wir in
              Vorleistung gehen. Dies bedeutet, dass Sie die beauftragten
              Leistungen ab Vertragsbeginn nutzen können, während die
              Abbuchungen erst Mitte des Monats fällig werden. Nähere
              Informationen zur Tätigkeit der Auskunftei können online
              unter https://www.creditreform-solingen.de/eu-dsgvo.htmleingesehen
              werden.{" "}
            </li>
            <li>
              Im Rahmen der Nutzung unseres Ticketshops über die HandyTicket
              Deutschland-App geben wir Ihre personenbezogenen Daten nur weiter,
              wenn wir hierzu datenschutzrechtlich berechtigt oder verpflichtet
              sind. Wir geben Ihre personenbezogenen Daten (Vor- und Nachname,
              Geburtsdatum, Adresse, E-Mail-Adresse, Kontoverbindung,
              Kreditkartendaten, ggf. Mobilfunknummer sowie Daten zu Ihren
              jeweiligen Ticketkäufen) und alle Änderungen an die LogPay
              Financial Services GmbH zum Zwecke des Verkaufes und der Abtretung
              unserer Forderungen gegen Sie, welche im Zusammenhang mit Ihrem
              Ticketkauf entstehen, weiter. Dies erfolgt auf Grundlage des Art.
              6 Abs. 1 S. 1 lit. f DS-GVO. Das berechtigte Interesse auf unserer
              Seite besteht in der Auslagerung der Zahlungsabwicklung und des
              Forderungsmanagements. Das berechtigte Interesse auf Seiten der
              LogPay Financial Services GmbH besteht in der Erhebung der Daten
              zum Zwecke der Abwicklung von Zahlungen, zum Forderungsmanagement,
              der Bewertung der Zulässigkeit von Zahlarten und der Vermeidung
              von Zahlungsausfällen. Sie können der Übermittlung dieser Daten an
              die LogPay Financial Services GmbH jederzeit widersprechen,
              allerdings ist dann keine Bestellung mehr über den elektronischen
              Vertriebskanal möglich.Die datenschutzrechtlichen Informationen
              der LogPay Financial Services GmbH können Sie
              unter https://landingpage.logpay.de/mobility_dsgvo_2018/ abrufen.
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.{" "}
            </li>
          </ul>
          <p>2.13 Dauer der Speicherung </p>
          <p>
            Wir löschen Ihre personenbezogenen Daten unverzüglich nach
            Zweckerreichung. So speichern wir Ihre Daten aus Ihren E-Mails bis
            Ihre Anfrage vollständig bearbeitet und erledigt ist. Danach werden
            die Angaben in der Regel gelöscht. Im Übrigen wird in jährlichen
            Turni geprüft, ob eine Löschung der Daten erfolgen kann.{" "}
          </p>
          <p>
            Session-Cookies werden nach Ihrem Website-Besuch automatisch von uns
            gelöscht. Zugriffsdaten (siehe oben) werden nach einer Woche
            gelöscht.{" "}
          </p>
          <p>
            Bewerben Sie sich online, löschen wir Ihre übermittelten
            personenbezogenen Daten zwölf Monate nach Abschluss des
            Bewerbungsverfahrens.{" "}
          </p>
          <h3>3. Newsletter </h3>
          <p>3.1 Newsletterdaten </p>
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen
            möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der
            Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des
            Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur
            auf freiwilliger Basis erhoben. Für die Abwicklung der Newsletter
            nutzen wir Newsletterdiensteanbieter, die nachfolgend beschrieben
            werden.{" "}
          </p>
          <p>3.2 CleverReach </p>
          <p>
            Diese Website nutzt CleverReach für den Versand von Newslettern.
            Anbieter ist die CleverReach GmbH & Co. KG, Schafjückenweg 2, 26180
            Rastede, Deutschland (nachfolgend „CleverReach“). CleverReach ist
            ein Dienst, mit dem der Newsletterversand organisiert und analysiert
            werden kann. Die von Ihnen zwecks Newsletterbezug eingegebenen Daten
            (z. B. E-Mail-Adresse) werden auf den Servern von CleverReach in
            Deutschland bzw. Irland gespeichert.{" "}
          </p>
          <p>
            Unsere mit CleverReach versandten Newsletter ermöglichen uns die
            Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a.
            analysiert werden, wie viele Empfänger die Newsletternachricht
            geöffnet haben und wie oft welcher Link im Newsletter angeklickt
            wurde. Mit Hilfe des sogenannten Conversion-Trackings kann außerdem
            analysiert werden, ob nach Anklicken des Links im Newsletter eine
            vorab definierte Aktion (z. B. Kauf eines Produkts auf dieser
            Website) erfolgt ist. Weitere Informationen zur Datenanalyse durch
            CleverReach-Newsletter erhalten Sie
            unter: https://www.cleverreach.com/de/funktionen/reporting-und-tracking/.{" "}
          </p>
          <p>
            Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit
            der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
            unberührt.{" "}
          </p>
          <p>
            Wenn Sie keine Analyse durch CleverReach wollen, müssen Sie den
            Newsletter abbestellen. Hierfür stellen wir in jeder
            Newsletternachricht einen entsprechenden Link zur Verfügung.{" "}
          </p>
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
            uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
            Abbestellung des Newsletters aus der Newsletterverteilerliste
            gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden,
            bleiben hiervon unberührt.{" "}
          </p>{" "}
          <p>
            Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
            E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
            einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
            Mailings erforderlich ist. Die Daten aus der Blacklist werden nur
            für diesen Zweck verwendet und nicht mit anderen Daten
            zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem
            Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand
            von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1
            lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht
            befristet.
            <b>
               Sie können der Speicherung widersprechen, sofern Ihre Interessen
              unser berechtigtes Interesse überwiegen.
            </b>{" "}
          </p>{" "}
          <p>
            Näheres entnehmen Sie den Datenschutzbestimmungen von CleverReach
            unter: https://www.cleverreach.com/de/datenschutz/.{" "}
          </p>{" "}
          <p>
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung
            des oben genannten Dienstes geschlossen. Hierbei handelt es sich um
            einen datenschutzrechtlich vorgeschriebenen Vertrag, der
            gewährleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
            DSGVO verarbeitet.{" "}
          </p>
          <Link to="/">Zurück</Link>
        </div>
      </div>
    </>
  );
}
