import React from "react";
import "./index.scss";
import { useNavigate, Link } from "react-router-dom";

export default function MainBlock({ data, id }) {
  const navigate = useNavigate();

  return (
    <div className="formular-field" style={{ marginTop: id == 0 ? -32 : 0 }}>
      <div className="formular-content">
        <div className="formular-sub-title">{data.subtitle}</div>
        <div className="formular-title">{data.title}</div>
        <div className="formular-box-list">
          {data?.box &&
            data?.box.map((box, index) => {
              if (box.title) {
                return (
                  <Link to={box.url} className="formular-box">
                    {box.teaser && (
                      <div className="box-teaser">{box.teaser}</div>
                    )}
                    <img src={box.img} />
                    <div className="box-title">{box.title}</div>
                    <div className="box-subline">{box.subtitle}</div>
                    <div className="link">Mehr erfahren</div>
                  </Link>
                );
              } else {
                return <div></div>;
              }
            })}
        </div>
      </div>
    </div>
  );
}
