import React, { useState } from "react";
import "./index.scss";
import Arrow from "../assets/down.svg";
export default function Accordion({ question, answer }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`accordion-single ${open ? "is-open" : "is-closed"}`}
      onClick={() => setOpen(!open)}
    >
      <div className="accordion-question">
        {question}
        <img src={Arrow}></img>
      </div>
      <div className={`accordion-content ${open ? "open" : ""}`}>
        <div style={{ height: 12 }}></div>
        {answer}
      </div>
    </div>
  );
}
