import React, { useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { useFormspark } from "@formspark/use-formspark";
import Loader from "../components/Loader";
import JobLogo from "../assets/joblogo.png";
export default function Formular() {
  const [name, setName] = useState("");
  const [adresse, setAdresse] = useState("");
  const [partnername, setPartnername] = useState("");
  const [tel, setTel] = useState("");
  const [erreich, setErreich] = useState("");
  const [workernumber, setWorkernumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("form");
  const [submitted, setSubmitted] = useState(false);

  const [submit, submitting] = useFormspark({
    formId: "aCxrHwHM",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const submitFunction = await submit({
      Name: name,
      Adresse: adresse,
      Ansprechpartner: partnername,
      Telefon: tel,
      Erreichbarkeit: erreich,
      Miutarbeiteranzahl: workernumber,
    });
    if (submitFunction) {
      setSubmitted(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="header small"
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          zIndex: 1,
          padding: "2rem",
          paddingBottom: "6rem",
        }}
      >
        <img src={JobLogo} alt="" />
      </div>
      <div className="content" style={{ zIndex: 999999, position: "relative" }}>
        {submitted ? (
          <div>
            <h2>Einsendung erfolgreich.</h2>
            <p>
              Wir werden uns in Kürze mit dem Ansprechpartner in deinem
              Unternehmen in Verbindung setzen.
            </p>
          </div>
        ) : (
          <form
            onSubmit={onSubmit}
            className="job-formular-content"
            style={{ marginTop: -120, zIndex: 999999 }}
          >
            <div className="formular-title">
              Antragsformular zum Deutschlandticket Job
            </div>
            <div className="job-text">Im Namen der</div>
            <label className="job-label">Firma:</label>
            <input
              className="job-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label className="job-label">Adresse des Firmensitzes:</label>
            <input
              className="job-input"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
            />
            <div className="job-text" style={{ marginTop: 20 }}>
              möchte ich nähere Informationen zum Deutschlandticket Job
              erhalten. Ich bitte Sie, zur weiteren Beratung oder
              Vertragserstellung mit folgendem Ansprechpartner in unserem
              Unternehmen Kontakt aufzunehmen:
            </div>
            <label className="job-label">Name des Ansprechpartners:</label>

            <input
              className="job-input"
              value={partnername}
              onChange={(e) => setPartnername(e.target.value)}
            />
            <label className="job-label">
              Telefonnummer zur Kontaktaufnahme:
            </label>

            <input
              className="job-input"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
            <label className="job-label">Erreichbarkeit (telefonisch):</label>

            <input
              className="job-input"
              value={erreich}
              onChange={(e) => setErreich(e.target.value)}
            />
            <label className="job-label">Anzahl der Mitarbeiter:</label>

            <input
              className="job-input"
              value={workernumber}
              onChange={(e) => setWorkernumber(e.target.value)}
            />
            <button className="job-button" type="submit" disabled={submitting}>
              Senden
            </button>
            {loading && <Loader />}
          </form>
        )}
      </div>
    </>
  );
}
