import logo from "./logo.svg";
import { useEffect } from "react";
import "./index.scss";
import "./components/blockeditor.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";

import Impressum from "./Pages/Impressum";
import Datenschutz from "./Pages/Datenschutz";
import Formular from "./Pages/Formular";
import Quiz from "./Pages/Quiz";
import Layout from "./components/Layout";
import Page from "./Pages/Page";
function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Layout>
      <div className="App">
        <Routes>
          <Route path="/impressum" element={<Impressum />}></Route>
          <Route path="/datenschutz" element={<Datenschutz />}></Route>
          <Route path="/form" element={<Formular />}></Route>
          <Route path="/gewinnspiel" element={<Quiz />}></Route>
          <Route path="/:slug" element={<Page />}></Route>
          <Route path="/" element={<Dashboard />}></Route>
        </Routes>
      </div>
    </Layout>
  );
}

export default App;
