import React, { useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";

export default function Impressum() {
    return (
        <>
            <div className='header small'>
                <h1 className='header-title-single'>Impressum</h1>
            </div>
            <div className='content'>
                <div className='inner'>
                    <p>
                        <h2>Stadtwerke Solingen GmbH – Verkehrsbetrieb</h2>
                    </p>
                    <p>
                        Weidenstraße 10, 42655 Solingen
                        <br />
                        Telefon: + 49 0212 / 295 – 2201
                        <br />
                        Telefax: + 49 0212 / 295 – 2207
                    </p>
                    <p>
                        Geschäftsführer: Leif Reitis
                        <br />
                        Registergericht: Amtsgericht Wuppertal HRB 15873
                    </p>
                    <p>
                        USt-IdNr. DE174657710 Inhaltlich Verantwortlicher gemäß
                        § 6 MDStV: Silke Tanja Rampe
                    </p>
                    <p>
                        Rechtliche Hinweise:{" "}
                        <Link to='/datenschutz'>Datenschutz</Link>
                    </p>
                    <p>
                        <b>Inhalt der Internetpräsenz</b> Wir übernehmen
                        keinerlei Gewähr für die Aktualität, Korrektheit,
                        Vollständigkeit oder Qualität der bereitgestellten
                        Informationen. Haftungsansprüche gegen uns, welche sich
                        auf Schäden materieller oder ideeller Art beziehen, die
                        durch die Nutzung oder Nichtnutzung der dargebotenen
                        Informationen bzw. durch die Nutzung fehlerhafter und
                        unvollständiger Informationen verursacht wurden sind
                        grundsätzlich ausgeschlossen, sofern kein nachweislich
                        vorsätzliches oder grob fahrlässiges Verschulden
                        vorliegt. Alle Angebote sind freibleibend und
                        unverbindlich. Wir behalten es ausdrücklich vor, Teile
                        der Seiten oder das gesamte Angebot ohne gesonderte
                        Ankündigung zu verändern, zu ergänzen, zu löschen oder
                        die Veröffentlichung zeitweise oder endgültig
                        einzustellen.
                    </p>
                    <p>
                        <b>Streitschlichtung</b> Informationen zur
                        Online-Streitbeilegung gemäß Art. 14 Abs. 1 VO (EU)
                        524/2013: Verbraucher haben die Möglichkeit, über die
                        Online-Streitbeilegungs-Plattform (OS-Plattform) der
                        Europäischen Union kostenlose Hilfestellung für die
                        Einreichung einer Verbraucherbeschwerde zu einem
                        Online-Kaufvertrag oder Online-Dienstleistungsvertrag
                        sowie Informationen über die Verfahren an den
                        Verbraucherschlichtungsstellen in der Europäischen Union
                        zu erhalten. Die OS-Plattform kann aufgerufen werden
                        unter folgendem Link:{" "}
                        <a
                            href='http://ec.europa.eu/consumers/odr/'
                            target='_blank'>
                            http://ec.europa.eu/consumers/odr/.
                        </a>
                    </p>
                    <p>
                        {" "}
                        Die Teilnahme an Schlichtungsverfahren im Sinne des § 36
                        VSGB ist für den Verkehrsbetrieb der Stadtwerke Solingen
                        GmbH nicht verpflichtend. Bitte wenden Sie sich in
                        diesen Angelegenheiten direkt an uns (siehe Rubrik
                        Ansprechpartner – telefonisch – oder Kontakt – per
                        E-Mail-), da wir hier an Schlichtungsverfahren vor
                        Schlichtungsstellen nicht teilnehmen.
                    </p>
                    <Link to='/'>Zurück</Link>
                </div>
            </div>
        </>
    );
}
