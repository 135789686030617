import React, { useEffect, useState } from "react";
import "./index.scss";
import Arrow from "../assets/arrow.svg";
import ClickAwayListener from "react-click-away-listener";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function SingleMenu({ data, setMenuOpen }) {
  const [submenu, setSubmenu] = useState(false);
  const navigate = useNavigate();

  if (data.url == "/#faq") {
    return (
      <div onClick={() => setMenuOpen(false)}>
        <HashLink
          to={"/#faq"}
          className="menu-item"
          style={{ textDecoration: "none" }}
        >
          <div>FAQ</div>
        </HashLink>
      </div>
    );
  }

  return (
    <div
      className="menu-item"
      onMouseEnter={() => {
        setSubmenu(true);
      }}
      onMouseLeave={() => {
        setSubmenu(false);
      }}
      onClick={() => {
        if (!data.subMenu) {
          navigate(data.url);
          setMenuOpen(false);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.label}
        {data.subMenu && (
          <img style={{ width: 12, marginLeft: 6 }} src={Arrow} />
        )}
      </div>
      {data.subMenu && submenu && (
        <ClickAwayListener onClickAway={() => setSubmenu(false)}>
          <div className="submenu-list">
            {data.subMenu.map((singleSub, idx) => (
              <div
                className="singlesub"
                onClick={() => {
                  navigate(singleSub.url);
                  setMenuOpen(false);
                }}
              >
                {singleSub.label}
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
