import React, { useEffect, useState } from "react";
import "./index.scss";
import Ex from "../assets/image.png";

import Swslogo from "../assets/stadwerkelogo_white.svg";
import { Link } from "react-router-dom";
import Stop from "../assets/stop.svg";
import Done from "../assets/done.svg";
import MenuData from "../assets/menu.json";
import SingleMenu from "./SingleMenu";
import Menu from "../assets/menu.svg";
import { useNavigate } from "react-router-dom";
import Close from "../assets/icons8-close.svg";

export default function Layout({ children }) {
    const [open, setOpen] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const time = new Date();
        let day = time.getDay();
        let hour = time.getHours();
        console.log(day, hour);
        if (day > 5 || hour < 9 || hour > 17) {
            setOpen(false);
        }
    });
    return (
        <div>
            <div className='top-header'>
                <div className='header-bar'>
                    <div className='header-bar-container'>
                        <div className='logo'>
                            <a href='/'>
                                <img src={Swslogo} style={{ height: 40 }}></img>
                            </a>
                        </div>

                        <div
                            className={`menu-list-out ${
                                menuOpen ? " open" : ""
                            }`}>
                            <div
                                className='close'
                                onClick={() => setMenuOpen(false)}>
                                <img src={Close} />
                            </div>
                            <div className='menu-list'>
                                <div
                                    className='menu-item start'
                                    onClick={() => {
                                        setMenuOpen(false);
                                        navigate("/");
                                    }}>
                                    Startseite
                                </div>
                                {MenuData?.menu.map((menu, idx) => (
                                    <SingleMenu
                                        data={menu}
                                        key={"menu" + idx}
                                        setMenuOpen={setMenuOpen}
                                    />
                                ))}
                            </div>
                        </div>
                        <div
                            className='menu-burger'
                            onClick={() => setMenuOpen(!menuOpen)}>
                            <img style={{ height: 32 }} src={Menu} />
                        </div>
                    </div>
                </div>
                <div className='contact-box'>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}>
                        <div>
                            <div
                                style={{
                                    color: "white",
                                    fontSize: 12,
                                    letterSpacing: 1.2,
                                    textAlign: "end",
                                }}>
                                WIR HELFEN GERNE
                            </div>

                            <a
                                href='tel:+492122952151'
                                style={{ color: "white" }}>
                                + 49 212 / 295 – 2151
                            </a>
                        </div>
                        <div
                            className='user-Image'
                            style={{
                                backgroundImage: `url(${Ex})`,
                            }}>
                            <div
                                className='status'
                                style={{
                                    backgroundColor: open ? "#48EC05" : "red",
                                    backgroundImage: open
                                        ? `url(${Done})`
                                        : `url(${Stop})`,
                                }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
            <div className='footer'>
                <div>(C) 2023 Stadtwerke Solingen GmbH Verkehrsbetrieb</div>
                <div>
                    <Link to='/impressum'>Impressum</Link> |{" "}
                    <Link to='/datenschutz'>Datenschutz</Link> |
                    <a href='https://www.gut-vorankommen.de/'>Homepage</a>
                </div>
            </div>
        </div>
    );
}
